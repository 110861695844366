import React from 'react';
import SEO from '../components/seo';
import { useStaticQuery, graphql } from 'gatsby';
import CompanyForm from '../components/CompanyForm';
import Layout from '../components/layout';

const CompanyPage = () => {

  const Data = useStaticQuery(graphql`
  	query {
      allContentfulPages(filter: {slug: {eq: "opdracht-plaatsen"}}) {
        edges {
          node {
            title
            slug
            metaTitle
            metaDescription
          }
        }
      }

      allContentfulFormFields {
        edges {
          node{
            companyPageTitle
            companyPageSubTitle
            companyPageFormTitle
            companyFieldTextFormTwo
            nameFieldTextFormTwo
            emailFieldTextFormTwo
            phoneFieldTextFormTwo
            checkboxFieldTextFormTwo
            buttonCaptionTextFormTwo
            supportTitlePageSidebar
            supportDescriptionPageSidebar{
              supportDescriptionPageSidebar
            }
            supportButtonLinkPageSidebar
            supportButtonCaptionPageSidebar
            worldwideTitlePageSidebar
            worldwideDescriptionPageSidebar{
              worldwideDescriptionPageSidebar
            }
            worldwideButtonCaptionPageSidebar
            worldwideButtonLinkPageSidebar
            callUsTextPageSidebar
            callUsNumberPageSidebar
            becomeADistributorText
            distributorButtonLinkPageSidebar
            distributorButtonCaptionPageSidebar
            mediaInquiriesTextPageSidebar
            mediaButtonCaptionPageSidebar
            mediaButtonLinkPageSidebar
          }
        }
      }
    }
  `);

  const metaData = Data.allContentfulPages.edges[0].node;

  const formData = Data.allContentfulFormFields.edges[0].node;

  return (
    <>
      <SEO
          title={metaData.metaTitle}
          description={metaData.metaDescription}
        />
        <Layout>
			<CompanyForm formData={formData}/>
    	</Layout>
    </>
  );

}

export default CompanyPage;
