import React from 'react'
import { Container, Col, Row} from 'react-bootstrap';
import { FormContainer, FormWrapper, SidebarWrapper} from "./form.style";
import { Link } from 'gatsby';
import { Icon } from 'react-icons-kit';
import {longArrowRight} from 'react-icons-kit/fa/longArrowRight';
import {phone} from 'react-icons-kit/icomoon/phone';
import {lightbulb} from 'react-icons-kit/ionicons/lightbulb';
import {bullhorn} from 'react-icons-kit/icomoon/bullhorn';

const CompanyFormSection = (props) => {
  
  const data = props;
  return (
    
    <FormContainer>
    <Container>
        <Row>
          <Col>
            <div className="form-header">
                <h2>{data.formData.companyPageTitle}</h2>
                <p>{data.formData.companyPageSubTitle}</p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={7} xs={12}>
            <FormWrapper>
              <form name="company" action="/opdracht-plaatsen-success" method="post" data-netlify="true" data-netlify-honeypot="bot-field">
              <input type="hidden" name="bot-field" />
              <input type="hidden" name="form-name" value="company" />
                    <div className="form-group">
                      <label htmlFor="inputaboutassignment">{data.formData.companyPageFormTitle}</label>
                      <input
                        id="inputaboutassignment"
                        className="form-control"
                        name="aboutassignment"
                        type="text"
                        placeholder=""
                        required/>  
                    </div>
                    <div className="form-group">
                      <label htmlFor="inputcompany">{data.formData.companyFieldTextFormTwo}</label>
                      <input
                        id="inputcompany"
                        className="form-control"
                        name="company"
                        type="text"
                        placeholder=""
                        required/>  
                    </div>
                    <div className="form-group">
                      <label htmlFor="inputname">{data.formData.nameFieldTextFormTwo}</label>
                      <input
                        id="inputname"
                        className="form-control"
                        name="name"
                        type="text"
                        placeholder=""
                        required/>  
                    </div>
                    <div className="form-group">
                    <label htmlFor="inputemail">{data.formData.emailFieldTextFormTwo}</label>
                    <input
                      id="inputemail"
                      className="form-control"
                      name="email"
                      type="email"
                      placeholder=""
                      required/>  
                    </div>
                    <div className="form-group">
                    <label htmlFor="inputphone">{data.formData.phoneFieldTextFormTwo}</label>
                    <input
                      id="inputphone"
                      className="form-control"
                      name="phone"
                      type="text"
                      placeholder=""
                    />  
                    </div>
                    <div className="form-group">
                      <label className="checkbox-inline">
                        <input type="checkbox" value="" required/><Link to="/terms"> {data.formData.checkboxFieldTextFormTwo} </Link>
                      </label>
                    </div>  
                    <div className="submit-text">
                      <button className="btn btn-block">{data.formData.buttonCaptionTextFormTwo}</button>
                    </div>
              </form>
            </FormWrapper>
          </Col>
          <Col md={5} xs={12}> 
            <SidebarWrapper>
                <div className="vrcard">
                  <h3>{data.formData.supportTitlePageSidebar}</h3>
                  <p>{data.formData.supportDescriptionPageSidebar.supportDescriptionPageSidebar}</p>
                  <button className="btn rollerbtn-light"><a href={data.formData.supportButtonLinkPageSidebar}>{data.formData.supportButtonCaptionPageSidebar} <Icon icon={longArrowRight} size={18} /></a></button>
                </div>
                <div className="vrcard">
                  <h3>{data.formData.worldwideTitlePageSidebar}</h3>
                  <p>{data.formData.worldwideDescriptionPageSidebar.worldwideDescriptionPageSidebar}</p>
                  <button className="btn rollerbtn-light"><a href={data.formData.worldwideButtonLinkPageSidebar}>{data.formData.worldwideButtonCaptionPageSidebar} <Icon icon={longArrowRight} size={18} /></a></button>
                </div>
                <div className="vrcard">
                  <h3>{data.formData.callUsTextPageSidebar}</h3>
                  <button className="btn rollerbtn-light ico-left"><Icon icon={phone} size={20} /> {data.formData.callUsNumberPageSidebar}</button>
                </div>

                <div className="vrcard">
                  <h3>{data.formData.becomeADistributorText}</h3>
                  <button className="btn rollerbtn-light ico-left"><a href={data.formData.distributorButtonLinkPageSidebar}><Icon icon={bullhorn} size={20} />{data.formData.distributorButtonCaptionPageSidebar}</a></button>
                </div>

                <div className="vrcard">
                  <h3>{data.formData.mediaInquiriesTextPageSidebar}</h3>
                  <button className="btn rollerbtn-light ico-left"><a href={data.formData.mediaButtonLinkPageSidebar}><Icon icon={lightbulb} size={20} /> {data.formData.mediaButtonCaptionPageSidebar}</a></button>
                </div>
            </SidebarWrapper> 
          </Col>
        </Row>
      </Container>
      </FormContainer>
  );
};
export default CompanyFormSection;
