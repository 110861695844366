import styled from 'styled-components';

export const FormContainer = styled.div`
  margin: 50px 0px;
  .form-header {
  	  padding-bottom: 40px;
	  h2 {
	    font-size: 41px;
	    color: #262626;
	    font-family: "Runda W00 Bold" !important;
	    text-transform: capitalize;
	  }
	  p {
		font-size: 24px;
	  }
  }
`;



export const FormWrapper = styled.div`
   
	input[type=text], input[type=number], input[type=email] {
	    border: 0px;
	    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
	    border-radius: 0px;
	    font-size: 15px;
	    :focus{
	    	box-shadow: none !important;
	    }
	}
	input[type="checkbox"]{
	  	width: 15px;
	    height: 15px;
	    cursor: pointer;
	    vertical-align: middle;
	    margin-right: 10px;
	}
	form {
	    padding: 40px;
	    background-color: #fff;
	    box-shadow: 0px 0px 8px 1px #efe8e8;
	    border-radius: 4px;
	    @media (max-width: 600px) {
	      padding: 15px;
	    } 
	}
	label{
		vertical-align: middle !important;
	    color: rgba(0,0,0,0.65);
	    font-size: 13px;
	    line-height: 1;
	    font-family: "Runda W00 medium" !important;
	}
	.checkbox-inline {
		a{
			color: #3c3a3a !important;
		    text-transform: capitalize;
		    font-size: 13px !important;
		    font-family: "Runda W00 Bold" !important;
		    text-decoration: underline;
		}
	}
	.submit-text{
		button {
		    background-color: rgb(32, 211, 64);
		    border-color: rgb(32, 211, 64);
		    font-size: 18px;
		    font-family: "Runda W00 Bold" !important;
		    padding: 10px 0px;
		    color: #fff;
		    :hover {
		    	background-color: rgb(32, 211, 64);
		    }
		}
	} 
`;

export const SidebarWrapper = styled.div`
	padding-left: 85px;
	@media (max-width:500px) {
		padding: 20px 10px;
	}
	.vrcard {
    	border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    	margin-bottom: 20px;
    	padding-bottom: 20px;
		h3 {
		    font-family: "Runda W00 bold" !important;
		    font-size: 20px;
		}
		p {
			padding-top: 15px;
			color: rgba(0, 0, 0, 0.65);
		    font-size: 1rem;
		    line-height: 1.46429em;
		    margin: 0;
		}
		.rollerbtn-light {
		    background-color: transparent;
		    color: #20d340;
		    font-family: Runda W00 bold;
		    border: 0px;
		    margin-left: -10px;
		    :hover {
		    	background-color: rgba(32, 211, 64, 0.08);
		    }
		    a{
				color: #20d340;
				:hover {
		    		color: #20d340;
		    	}
		    }
		}
		.ico-left i {
		    margin-right: 8px;
		}
	}
	
`;